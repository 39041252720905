import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { MdLocationOn } from "react-icons/md";

function Checkpoints({ driverFreight, checkpoints, setViewport }) {
    const { location } = useStaticQuery(graphql`
        query {
            location: file(relativePath: { eq: "location.png" }) {
                childImageSharp {
                    fixed(height: 22, width: 16) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
        }
    `);

    return (
        <>
            <h5 className="mt-3 uppercase">Checkpoints da viagem</h5>
            <div className="overflow-auto" style={{ height: "calc( var(--heightt) - 75px )" }}>
                <table className="table-auto mt-6 w-full text-left ">
                    <thead className="border-b border-roxo_oficial">
                        <tr>
                            <th className="text-roxo_oficial">Velocidade média</th>
                            <th className="text-roxo_oficial">Data</th>
                        </tr>
                    </thead>
                    <tbody>
                        <>
                            {checkpoints &&
                                checkpoints?.length > 0 &&
                                checkpoints.map(entity => {
                                    return (
                                        <tr key={entity.identifier}>
                                            <td>{entity.speedFormatted}km/h</td>
                                            <td>{entity.dateFormatted}</td>
                                            <td>
                                                <button
                                                    title="Ver no mapa"
                                                    onClick={() => {
                                                        setViewport({
                                                            latitude: entity?.latitude,
                                                            longitude: entity?.longitude,
                                                            zoom: 15,
                                                        });
                                                    }}>
                                                    <MdLocationOn
                                                        fixed={location.childImageSharp.fixed}
                                                        size={30}
                                                        color={"#FF0000"}
                                                        className="mt-1"
                                                    />
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            {(!driverFreight || driverFreight?.checkpoints?.length === 0) && (
                                <tr className="text-center">
                                    <td className="text-center" colSpan="9">
                                        Sem checkpoints nesta viagem.
                                    </td>
                                </tr>
                            )}
                        </>
                    </tbody>
                </table>
            </div>
        </>
    );
}
export default Checkpoints;
